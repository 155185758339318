import moment from 'moment';
import {v4 as uuid} from 'uuid';
import {DateAmount} from "./date_amount";

export enum SubscriptionType {
    subscription = "SUBSCRIPTION",   // other subscriptions
    entertainment = "ENTERTAINMENT", //
    insurance = "INSURANCE",         //
    repayment = "REPAYMENT",         // loan, credit card repayments
    utility = "UTILITY",             // gas, electricity, water, telephone, rates, taxes
    school = "SCHOOL",               //
}

export enum SubscriptionInterval {
    weekly = "WEEKLY",
    monthly = "MONTHLY",
    quarter_yearly = "QUARTER_YEARLY",
    yearly = "YEARLY",
}

export class Subscription {
    id: string;
    name: string;
    type?: SubscriptionType
    interval?: SubscriptionInterval
    dateAmounts: DateAmount[];

    constructor() {
        this.id = uuid();
        this.name = '';
        this.dateAmounts = [];
        this.type = undefined;
        this.interval = undefined;
    }

    fromJson(obj: any) {
        this.id = obj.id ? obj.id : uuid();
        this.name = obj.name ? obj.name : '';
        this.type = obj.type ? obj.type : SubscriptionType.subscription;
        this.interval = obj.interval ? obj.interval : SubscriptionInterval.monthly;

        if (obj.dateAmounts) {
            for (let a of obj.dateAmounts) {
                const dateAmount = new DateAmount();
                dateAmount.fromJson(a);
                this.dateAmounts.push(dateAmount);
            }
        }
    }

    currentAmount() {
        const now = moment()
        let amount = new DateAmount();
        let multiplier = 1;
        if (this.interval === SubscriptionInterval.yearly) {
            multiplier = 1/12;
        } else if (this.interval === SubscriptionInterval.quarter_yearly) {
            multiplier = 4/12;
        } else if (this.interval === SubscriptionInterval.weekly) {
            multiplier = 4;
        }

        if (this.dateAmounts.length === 1) {
            amount.amount = this.dateAmounts[0].amount
            amount.effectiveDate = this.dateAmounts[0].effectiveDate
        } else {
            for (let o of this.dateAmounts.sort((first: DateAmount, second: DateAmount) => {
                if (first.effectiveDate && second.effectiveDate && first.effectiveDate > second.effectiveDate)
                    return 1
                else
                    return 0
            })) {
                if (o.effectiveDate && o.effectiveDate < now) {
                    amount.amount = o.amount
                    amount.effectiveDate = o.effectiveDate
                }
            }
        }
        amount.amount = amount.amount * multiplier;
        return amount
    }
}
