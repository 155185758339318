import {Outlet, Link} from "react-router-dom";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import * as React from "react";
import {useTranslation} from "react-i18next";


export async function loader() {
    return [];
}

export default function Root() {
    const { t } = useTranslation();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <CssBaseline />
                <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
                    <CssBaseline />
                    <Outlet/>
                </Container>
                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: 'auto',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[800],
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography variant="body1">
                            <Link to="/">{t('nav.dashboard')}</Link> | <Link to="/tools/calculator">{t('nav.mortgage_calculator')}</Link> | <Link to="/config">{t('nav.configuration')}</Link>
                        </Typography>
                        <Copyright />
                    </Container>
                </Box>
            </Box>
        </>
    );
}

function Copyright() {
    const { t } = useTranslation();

    return (
        <Typography variant="body2" color="text.secondary">
            {t('footer.copyright')}
            <a color="inherit" href="https://pocket-tools.de">
                Pocket Tools
            </a>{' '}
            {new Date().getFullYear()}
            {'.'} 2023-08-13 (2d65fac)
        </Typography>
    );
}
