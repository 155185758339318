export function formatValue(value: number, locale = 'en-us') {
    if (value === undefined) {
        return '';
    }
    if (value >= 0) {
        return new Intl.NumberFormat(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
    } else {
        let formatted = new Intl.NumberFormat(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(-value);
        formatted = '(' + formatted + ')';
        return formatted;
    }
}

export function round(value: number, precision: number) {
    if (precision < 0) {
        let factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    } else {
        return +(Math.round(Number(value + "e+" + precision)) +
            "e-" + precision);
    }
}
