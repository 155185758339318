import moment, {Moment} from 'moment';
import {v4 as uuidv4} from 'uuid';

export type ScheduleItem = {
    date: Moment | null,
    totalPayment: number,
    totalInterest: number,
    principal: number
};
export type Schedule = {
    schedule: ScheduleItem[],
    interest: number,
    payment: number
};

export class Loan {
    id: string;
    name: string;
    principal: number;
    interest: number;
    startDate: moment.Moment | null;
    repayment: number;
    term: number;

    constructor() {
        this.id = uuidv4();
        this.name = '';
        this.principal = 0;
        this.interest = 0;
        this.startDate = moment();
        this.repayment = 0;
        this.term = 0;
    }

    principalAtDate(toDate: moment.Moment): number {
        let principal = this.principal;
        if (toDate.diff(this.startDate, 'months') > 0) {
            const start = moment(this.startDate).endOf('month');
            const days = start.diff(this.startDate, 'days');
            const startInterest = this.round(this.principal * this.interest / 100 / 365 * days, 2);
            principal = principal - this.repayment + startInterest;
            while (toDate.diff(start, 'months') > 0) {
                const interest = this.round(this.principal * this.interest / 100 / 12, 2);
                principal = principal + interest - this.repayment;
                start.add(1, 'months');
            }
        }
        return this.round(principal, 2);
    }

    schedule(): Schedule {
        let principal = this.principal;
        let totalInterest = 0;
        let totalPayment = 0;
        let scheduleItems: ScheduleItem[] = [
            {
                date: moment(this.startDate),
                totalPayment: 0,
                totalInterest: 0,
                principal: principal,
            }
        ];

        const start = moment(this.startDate).endOf('month');

        const days = start.diff(this.startDate, 'days');
        const startInterest = this.round(this.principal * this.interest / 100 / 365 * days, 2);
        principal = this.round(principal - this.repayment + startInterest, 2);
        totalInterest += +startInterest;
        totalPayment += +this.repayment;

        scheduleItems.push({
            totalPayment: this.repayment,
            totalInterest: startInterest,
            date: moment(start),
            principal: principal,
        });

        while (principal > 0) {
            const interest = this.round(principal * this.interest / 100 / 12, 2);
            principal = this.round(principal + interest - this.repayment, 2);
            start.add(1, 'months').endOf('month');
            if (principal < 0) {
                totalInterest += +interest;
                totalPayment += +this.repayment;
                scheduleItems.push({
                    totalPayment: 0,
                    totalInterest: 0,
                    date: moment(start),
                    principal: 0,
                });
            } else {
                totalInterest += +interest;
                totalPayment += +this.repayment;
                scheduleItems.push({
                    date: moment(start),
                    principal: principal,
                    totalPayment: this.repayment,
                    totalInterest: interest,
                });
            }
        }
        return {
            schedule: scheduleItems,
            interest: totalInterest,
            payment: totalPayment
        };
    }

    tilgung(percentile: number) {

    }

    fromJson(obj: any) {
        this.id = obj.id ? obj.id : uuidv4();
        this.name = obj.name ? obj.name : '';
        this.principal = obj.principal ? obj.principal : 0;
        this.interest = obj.interest ? obj.interest : 0;
        this.startDate = obj.startDate ? moment(obj.startDate) : moment();
        this.repayment = obj.repayment ? obj.repayment : 0;
        this.term = obj.term ? obj.term : 0;
    }

    round(number: number, precision: number) {
        if (precision < 0) {
            let factor = Math.pow(10, precision);
            return Math.round(number * factor) / factor;
        } else
            return +(Math.round(Number(number + "e+" + precision)) +
                "e-" + precision);
    }

    /**
     * let paymentClassic = (principal * interest) / (paymentsPerYear * (1 - (1 + (interest / paymentsPerYear)) ** (-paymentsPerYear * term)));
     *
     * Annuität bzw. Monatliche Rate = i + j / 12 * C0
     * let paymentTilgung = (tilgung + interest) / 12 * principal
     *
     * @param principal
     * @param interest
     * @param term
     * @param percentile
     */
    static calculatePayment(principal: number, interest: number, term: number, percentile = 0) {
        if (percentile > 0) {
            const repayment = ((+interest + +percentile) / 12) * +principal / 100;
            return repayment;
        } else {
            const paymentsPerYear = 12;
            const interestRate = interest / 100;
            const paymentClassic = (principal * interestRate) / (paymentsPerYear * (1 - (1 + (interestRate / paymentsPerYear)) ** (-paymentsPerYear * term)));
            return paymentClassic;
        }
    }
}
