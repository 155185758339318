import {Loan} from "../models/loan";
import {useLoaderData, useNavigate} from "react-router-dom";
import {
    Alert,
    Box,
    Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import * as React from "react";
import {deleteLoan} from "../services/loan_service";
import {useTranslation} from "react-i18next";
import {formatValue} from "../helpers/format";

type Objects = { loans: Loan[] }

export default function Setup() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {loans} = useLoaderData() as Objects;

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                {t('title.setup')}
            </Typography>
            <Box sx={{my: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                        <Typography variant="h5" component="h2" gutterBottom>
                            {t('subtitle.loans')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button className="pull-right" variant="outlined" href="/config/loan/new">
                            {t('action.new_loan')}
                        </Button>
                    </Grid>
                    {loans.length ? (
                        <>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="medium">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('label.loan.name')}</TableCell>
                                            <TableCell align="right">{t('label.loan.principal')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loans.map((row: Loan) => (
                                            <TableRow
                                                hover
                                                onClick={() => navigate(`/config/loan/${row.id}/edit`)}
                                                key={row.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name || '<no name>'}
                                                </TableCell>
                                                <TableCell align="right">{formatValue(row.principal)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} sm={12}>
                                <Alert severity="info">{t('message.no_loans')}</Alert>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </>
    );
}

