import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n/i18n';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme';

import Setup from './routes/setup';
import EditLoan from './routes/edit_loan';
import Root from './routes/root';
import Index from './routes/index';
import objectsLoader from './routes/loader';
import {getLoan} from "./services/loan_service";
import LoanSchedule from "./routes/loan_schedule";
import NewLoan from "./routes/new_loan";
import LoanCalculator, {loader as loanCalculatorLoader} from "./routes/loan_calculator";
import NiceModal from '@ebay/nice-modal-react';

const loanLoader = ({params}: { params: any }) => {
    return new Promise((resolve) => {
        resolve(getLoan(params.loanId));
    });
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        // errorElement: <ErrorPage />,

        // rootAction: rootAction,
        children: [
            {index: true, element: <Index/>, loader: objectsLoader,},
            {path: 'tools/calculator', element: <LoanCalculator/>, loader: loanCalculatorLoader},
            {path: 'config/', element: <Setup/>, loader: objectsLoader},
            {path: 'config/loan/new', element: <NewLoan/>},
            {path: 'config/loan/:loanId/edit', element: <EditLoan/>, loader: loanLoader},
            {path: 'loan/:loanId/schedule', element: <LoanSchedule/>, loader: loanLoader},
        ]
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <NiceModal.Provider>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </NiceModal.Provider>
    </React.StrictMode>
);
