import {Loan} from "../models/loan";

export const getLoan = (id: string) => {
    const loans = getLoans();
    for (let loan of loans) {
        if (loan.id === id) {
            return loan;
        }
    }
    return undefined;
};

export const getLoans = () => {
    let loans: Loan[] = []

    const json = localStorage.getItem('loans')
    if (json !== null && json.length > 0) {
        const objects = JSON.parse(localStorage.getItem('loans')!!);
        for (let obj of objects) {
            let loan = new Loan()
            loan.fromJson(obj)
            loans.push(loan)
        }
    }
    return loans.sort((a: Loan, b: Loan) =>
        (a.name > b.name) ? 1 : -1)
        ;
};

export const deleteLoan = (id: string) => {
    let loans: Loan[] = [];
    if (localStorage.getItem('loans') !== null) {
        loans = JSON.parse(localStorage.getItem('loans')!!);
    }
    let c = loans.filter((obj: Loan) => {
        return obj.id !== id
    });
    localStorage.setItem('loans', JSON.stringify(c));
}

export const updateLoan = (id: string, obj: Loan) => {
    let loans = getLoans();

    for (let c of loans) {
        if (c.id === id) {
            c.name = obj.name;
            c.principal = obj.principal;
            c.interest = obj.interest;
            c.repayment = obj.repayment;
            c.term = obj.term;
            c.startDate = obj.startDate;
            break;
        }
    }
    localStorage.setItem('loans', JSON.stringify(loans));
}

export const createLoan = (loan: Loan) => {
    let loans = getLoans();
    loans.push(loan);

    localStorage.setItem('loans', JSON.stringify(loans));
    return loan;
}
