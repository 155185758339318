import {Loan} from "../models/loan";
import {Box, Typography,} from "@mui/material";
import LoanForm from "../models/loan_form";
import {useTranslation} from "react-i18next";

export default function NewLoan() {
    const { t } = useTranslation();

    const loan = new Loan();

    return (
        <Box component="div">
            <Typography variant="h4" component="h1" gutterBottom>
                {t('title.new_loan')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('hint.loan.new')}
            </Typography>

            <LoanForm loan={loan} isNew={true} />
        </Box>
    );
}

