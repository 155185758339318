import {useLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {Loan} from "../models/loan";
import LoanForm from "../models/loan_form";

export default function EditLoan() {
    const { t } = useTranslation();

    const loan = useLoaderData() as Loan;

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>
                {t('title.loan.edit', {name: loan.name})}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('hint.loan.edit')}
            </Typography>

            <LoanForm loan={loan} isNew={false} />
        </Box>
    );
}
