import {Loan} from "../models/loan";
import {useLoaderData} from "react-router-dom";
import {
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import * as React from "react";
import {formatValue} from "../helpers/format";

export default function LoanSchedule() {
    const loan = useLoaderData() as Loan;
    const schedule = loan.schedule();
    const table = schedule.schedule;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple-table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">+ Interest {formatValue(schedule.interest)}</TableCell>
                        <TableCell align="center">- Repayment {formatValue(schedule.payment)}</TableCell>
                        <TableCell align="center">= Principal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.map((row) => (
                        <TableRow>
                            <TableCell>{row.date!!.format('YYYY-MM-DD')}</TableCell>
                            <TableCell align="right">{formatValue(row.totalInterest)}</TableCell>
                            <TableCell align="right">{formatValue(row.totalPayment)}</TableCell>
                            <TableCell align="right">{formatValue(row.principal)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

