import {
    Box,
    Button,
    Grid,
    Paper, Table,
    TableCell, TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import * as React from "react";
import {Form, useLoaderData} from "react-router-dom";
import {Loan} from "../models/loan";
import {formatValue} from "../helpers/format";
import {useTranslation} from "react-i18next";
import {getLoan} from "../services/loan_service";

export async function loader({request}: { request: any }) {
    const url = new URL(request.url);
    const principal = url.searchParams.get("principal");
    const interest = url.searchParams.get("interest");
    const term = url.searchParams.get("term");

    if (principal !== null) {
        const loan = new Loan();
        loan.principal = principal ? parseFloat(principal.toString()) : 0;
        loan.interest = interest ? parseFloat(interest.toString()) : 0;
        loan.term = term ? parseFloat(term.toString()) : 0;
        loan.repayment = Loan.calculatePayment(loan.principal, loan.interest, loan.term);
        return new Promise((resolve) => {
            resolve(loan);
        });
    } else {
        return null;
    }
}

export default function LoanCalculator() {
    const { t } = useTranslation();

    const loan = useLoaderData() as Loan;
    let schedule;
    if (loan) {
        schedule = loan.schedule()
    }

    return (
        <Box component="form">
            <Typography variant="h4" component="h1" gutterBottom>
                {t('title.mortgage_calculator')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('hint.calculate_help')}
            </Typography>
            <Form method="get" id="loan-form">
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.principal')}
                            name="principal"
                            margin="normal"
                            helperText={t('form.hint.loan.principal')}
                            defaultValue={loan ? loan.principal : ""}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.interest')}
                            name="interest"
                            margin="normal"
                            helperText={t('form.hint.loan.interest')}
                            defaultValue={loan ? loan.interest : ""}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.term')}
                            name="term"
                            margin="normal"
                            helperText={t('form.hint.loan.term')}
                            defaultValue={loan ? loan.term : ""}
                        />
                    </Grid>
                </Grid>
                <Button
                    sx={{mt: 3, mb: 2}}
                    fullWidth
                    variant="contained"
                    type="submit"
                >
                    {t('form.button.calculate')}
                </Button>
            </Form>
            {schedule && (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableRow>
                                <TableCell component="th">{t('schedule.monthly_repayment')}</TableCell>
                                <TableCell align="right">{formatValue(loan.repayment)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">{t('schedule.total_repayment')}</TableCell>
                                <TableCell align="right">{formatValue(schedule.payment)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">{t('schedule.total_interest')}</TableCell>
                                <TableCell align="right">{formatValue(schedule.interest)}</TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    );
}
