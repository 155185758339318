import moment from 'moment';
import {v4 as uuid} from 'uuid';

export enum AssetType {
    cash = "CASH",
    current_equities = "CURRENT_EQUITIES",
    accounts_receivable = "ACCOUNTS_RECEIVABLE",
    property = "PROPERTY",
    long_term_equities = "LONG_TERM_EQUITIES",
}

export class Asset {
    id: string;
    name: string;
    type?: AssetType
    value: number;
    valueDate?: moment.Moment | undefined;

    constructor() {
        this.id = uuid();
        this.name = '';
        this.type = undefined;
        this.value = 0;
        this.valueDate = undefined;
    }

    fromJson(obj: any) {
        this.id = obj.id ? obj.id : uuid();
        this.name = obj.name ? obj.name : '';
        this.value = obj.value ? obj.value : 0;
        this.type = obj.type ? obj.type : undefined;
        this.valueDate = obj.valueDate ? moment(obj.valueDate) : undefined;
    }
}
