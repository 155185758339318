import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default NiceModal.create(({title, body, confirm}: {title: any, body: any, confirm: any}) => {
    const {t} = useTranslation();

    const modal = useModal();
    return (
        <Dialog
            open={modal.visible}
            onClose={() => modal.hide()}
            TransitionProps={{
                onExited: () => modal.remove(),
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => modal.hide()}  variant="outlined">
                    {t('action.cancel')}
                </Button>
                <Button onClick={() => {
                    confirm();
                    modal.hide()
                }}                                     variant="outlined"
                        color="error"
                >
                    {t('action.proceed')}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
