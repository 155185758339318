import moment from 'moment';

export class DateAmount {
    amount: number;
    effectiveDate?: moment.Moment | null;

    constructor() {
        this.amount = 0;
        this.effectiveDate = undefined;
    }

    fromJson(obj: any) {
        const amount = Number(obj.amount); // if obj.amount is not set we also get NaN
        if (!isNaN(amount)) {
            this.amount = obj.amount;
        } else {
            this.amount = 0;
        }
        this.effectiveDate = obj.effectiveDate ? moment(obj.effectiveDate) : undefined;
    }
}
