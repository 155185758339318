import React from "react";
import {Loan} from "../models/loan";
import {Link, useLoaderData} from "react-router-dom";
import moment from 'moment';
import {
    Alert,
    Box, Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import {formatValue} from "../helpers/format";
import {useTranslation} from "react-i18next";

type Objects = { loans: Loan[] }

export default function Index() {
    const {t} = useTranslation();

    let {loans} = useLoaderData() as Objects;

    let totals = {
        liabilities: 0,
    };

    const rowTemplate = (props: any) => {
        return (
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row">
                    {props.indent ? '- ' : ''}{props.name}
                    {props.link && (
                        <Link to={props.link}>Schedule</Link>
                    )}
                </TableCell>
                <TableCell align="right">
                    {formatValue(props.value)}
                </TableCell>
            </TableRow>
        );
    }

    const [state, setState] = React.useState({
        liabilitiesOpen: false,
    });

    for (let c of loans) {
        totals.liabilities -= +c.principalAtDate(moment());
    }

    return (
        <Box>
            <Grid container>
                {loans.length ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                {t('label.loan.loans')}
                            </Typography>
                        </Grid>
                        {loans.map((row: Loan) => (
                            <>
                                <Grid item xs={8}>
                                    <Typography variant="body1" gutterBottom>
                                        {row.name} ({t('label.loan.principal')} { formatValue(row.principal)})
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" gutterBottom className="pull-right">
                                        {formatValue(row.principalAtDate(moment()))}
                                    </Typography>
                                </Grid>

                            </>
                        ))}
                        <Grid item xs={8}>
                            <Typography variant="body1" gutterBottom>
                                {t('label.loan.total')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" gutterBottom className="pull-right">
                                {formatValue(totals.liabilities)}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} sm={12}>
                            <Alert severity="info">
                                {t('message.no_loans')}&nbsp;
                                <Link to="/config">{t('message.no_loans_add_new')}</Link>
                            </Alert>
                        </Grid>
                    </>
                )}


            </Grid>
        </Box>
    );
}
