import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Navigate, useNavigate} from "react-router-dom";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {
    Button, FormControl, Grid, Paper,
    Table, TableBody, TableCell, TableContainer, TableRow,
    TextField, Typography,
} from "@mui/material";
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {Loan} from './loan';
import {createLoan, deleteLoan, updateLoan} from '../services/loan_service';
import {formatValue, round} from '../helpers/format';
import moment from "moment";
import NiceModal from "@ebay/nice-modal-react";
import MyDialog from "./my_dialog";

type LoanState = {
    name: string,
    principal: string,
    interest: string,
    term: string,
    startDate: moment.Moment | null | undefined,
    repayment: string,
    finished: boolean,
};

export default function LoanForm({loan, isNew}: { loan: Loan, isNew: boolean }) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    let [state, setState] = useState<LoanState>({
        name: loan.name,
        principal: loan.principal ? loan.principal.toString() : '',
        interest: loan.interest ? loan.interest.toString() : '',
        term: loan.term ? loan.term.toString() : '',
        startDate: loan.startDate ? loan.startDate : undefined,
        repayment: loan.repayment ? loan.repayment.toString() : '',
        finished: false,
    });

    const handleChange = (event: any) => {
        const value = event.target.value;
        const fieldName = event.target.name;
        setState(prevState => {
            const newState = {
                ...prevState,
                [fieldName]: value,
            };
            if ((fieldName === 'principal' || fieldName === 'interest' || fieldName === 'term') && (+newState.principal > 0 && +newState.interest > 0 && +newState.term > 0)) {
                newState.repayment = round(Loan.calculatePayment(+newState.principal, +newState.interest, +newState.term), 2).toString();
            }
            return newState;
        });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let updatedLoan = new Loan();
        updatedLoan.fromJson(state);

        if (isNew) {
            createLoan(updatedLoan);
        } else {
            updateLoan(loan.id, updatedLoan);
        }
        setState({
            ...state,
            finished: true
        })
    }

    return (
        <>
            {
                state.finished && (
                    <Navigate to={`/config`} replace={true}/>
                )
            }

            <form method="post" id="loan-form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="outlined-required"
                            label={t('form.label.loan.name')}
                            name="name"
                            onChange={handleChange}
                            value={state.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl
                            fullWidth
                            margin="normal"
                        >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    label={t('form.label.loan.start_date')}
                                    // inputFormat="YYYY-MM-DD"
                                    onChange={(newValue) => {
                                        setState({
                                            ...state,
                                            startDate: newValue
                                        });
                                    }}
                                    value={state.startDate}
                                    // renderInput={(params) => <TextField name="startDate" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.principal')}
                            name="principal"
                            margin="normal"
                            helperText={t('form.hint.loan.principal')}
                            defaultValue={state.principal}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.interest')}
                            name="interest"
                            margin="normal"
                            helperText={t('form.hint.loan.interest')}
                            defaultValue={state.interest}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.term')}
                            name="term"
                            margin="normal"
                            helperText={t('form.hint.loan.term')}
                            defaultValue={state.term}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="outlined-required"
                            label={t('form.label.loan.repayment')}
                            name="repayment"
                            margin="normal"
                            helperText={t('form.hint.loan.repayment')}
                            value={state.repayment}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            sx={{mt: 3, mb: 2}}
                            fullWidth
                            variant="contained"
                            type="submit"
                        >
                            {isNew ? (
                                <>
                                    {t('action.new_loan')}
                                </>
                            ) : (
                                <>
                                    {t('action.update_loan')}
                                </>
                            )
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            onClick={() => {
                                navigate('/config');
                            }}
                            fullWidth
                            variant="outlined"

                        >
                            {t('action.cancel')}
                        </Button>
                    </Grid>
                    {!isNew && (
                        <React.Fragment key="delete">
                            <Grid item xs={12} sm={12}>
                                <Button
                                    onClick={() => {
                                        NiceModal.show(MyDialog, {
                                            title: t('dialog.delete_loan_title'),
                                            body: t('dialog.delete_loan_text', {name: loan.name}),
                                            confirm: () => {
                                                deleteLoan(loan.id);
                                                navigate('/config');
                                            }
                                        })
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                >
                                    {t('action.delete_loan')}
                                </Button>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </form>
            {
                +state.repayment > 0 && (
                    <>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th">{t('schedule.monthly_repayment')}</TableCell>
                                        <TableCell component="th"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">
                                            <small>
                                                {t('schedule.equated_monthly')} <a
                                                href="https://en.wikipedia.org/wiki/Equated_monthly_installment">Wikipedia</a>
                                            </small>
                                        </TableCell>
                                        <TableCell align="right">{formatValue(+state.repayment)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">
                                            <small>{t('schedule.tilgung_4')} <a
                                                href="https://de.wikipedia.org/wiki/Annuit%C3%A4tendarlehen">Wikipedia</a></small>
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                formatValue(Loan.calculatePayment(+state.principal, +state.interest, +state.term, 4))
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">
                                            <small>{t('schedule.tilgung_5')}</small>
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                formatValue(Loan.calculatePayment(+state.principal, +state.interest, +state.term, 5))
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">
                                            <small>{t('schedule.tilgung_10')}</small>
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                formatValue(Loan.calculatePayment(+state.principal, +state.interest, +state.term, 10))
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
            }
        </>
    )
        ;
}

