import {Asset} from "../models/asset";

export const getAsset = (id: string) => {
    const assets = getAssets();
    for (let asset of assets) {
        if (asset.id === id) {
            return asset;
        }
    }
    return undefined;
};

export const getAssets = () => {
    let assets: Asset[] = []

    const json = localStorage.getItem('assets')
    if (json !== null && json.length > 0) {
        const objects = JSON.parse(localStorage.getItem('assets')!!);
        for (let obj of objects) {
            let asset = new Asset()
            asset.fromJson(obj)
            assets.push(asset)
        }
    }
    return assets.sort((a: Asset, b: Asset) =>
        (a.name > b.name) ? 1 : -1);
};

export const deleteAsset = (id: string) => {
    let assets: Asset[] = [];
    if (localStorage.getItem('assets') !== null) {
        assets = JSON.parse(localStorage.getItem('assets')!!);
    }
    let c = assets.filter((obj: Asset) => { return obj.id !== id });
    localStorage.setItem('assets', JSON.stringify(c));
}

export const updateAsset = (id: string, obj: Asset) => {
    let assets = getAssets();

    for (let a of assets) {
        if (a.id === id) {
            a.name = obj.name;
            a.value = obj.value;
            a.type = obj.type;
            break;
        }
    }

    localStorage.setItem('assets', JSON.stringify(assets));
}

export const createAsset = (asset: Asset) => {
    let assets = getAssets();
    assets.push(asset);

    localStorage.setItem('assets', JSON.stringify(assets));
    return asset;
}
