import {Subscription} from "../models/subscription";

export const getSubscription = (id: string) => {
    const subscriptions = getSubscriptions();
    for (let subscription of subscriptions) {
        if (subscription.id === id) {
            return subscription;
        }
    }
    return undefined;
};

export const getSubscriptions = () => {
    let subscriptions: Subscription[] = []

    const json = localStorage.getItem('subscriptions')
    if (json !== null && json.length > 0) {
        const objects = JSON.parse(localStorage.getItem('subscriptions')!!);
        for (let obj of objects) {
            let subscription = new Subscription()
            subscription.fromJson(obj)
            subscriptions.push(subscription)
        }
    }
    return subscriptions.sort((a: Subscription, b: Subscription) =>
        (a.name > b.name) ? 1 : -1);
};

export const deleteSubscription = (id: string) => {
    let subscriptions: Subscription[] = [];
    if (localStorage.getItem('subscriptions') !== null) {
        subscriptions = JSON.parse(localStorage.getItem('subscriptions')!!);
    }
    let c = subscriptions.filter((obj: Subscription) => {
        return obj.id !== id
    });
    localStorage.setItem('subscriptions', JSON.stringify(c));
}

export const updateSubscription = (id: string, subscription: Subscription) => {
    let subscriptions = getSubscriptions();

    for (let c of subscriptions) {
        if (c.id === id) {
            c.name = subscription.name;
            c.type = subscription.type;
            c.interval = subscription.interval;
            c.dateAmounts = subscription.dateAmounts;
            break;
        }
    }

    localStorage.setItem('subscriptions', JSON.stringify(subscriptions));
}

export const createSubscription = (subscription: Subscription) => {
    let subscriptions = getSubscriptions();
    subscriptions.push(subscription);

    localStorage.setItem('subscriptions', JSON.stringify(subscriptions));
    return subscription;
}
