import {getLoans} from "../services/loan_service";
import {getAssets} from "../services/asset_service";
import {getSubscriptions} from "../services/subscription_service";

export default async function objectsLoader() {
    const loans = getLoans();
    const assets = getAssets();
    const subscriptions = getSubscriptions();
    return {loans, assets, subscriptions};
}
